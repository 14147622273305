
















































































































































































































































































































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import { GetRenwuList, GetCategory, GetJindu } from "@/request/mark";
import MainHeader from "@/components/main-header/main-header.vue";
import Jingdu from "@/components/biaozhu/jingdu.vue";
import SearchFilter from "@/components/biaozhu/search-filter.vue";
@Component({
  components: {
    MainHeader,
    Jingdu,
    SearchFilter,
  },
})
export default class Name extends mixins(listMixin, quanxianMixin) {
  private jingduId: any = "";
  private data: any[] = [];
  private pageSetting: any = {};
  private fenleis: any = [];
  private biaozhuStatus: any = [
    "待标注",
    "标注中",
    "待审核",
    "已打回",
    "已通过",
  ];
  private ifShowJindu: any = false;
  private changeSearch(d: any) {
    d.list_type = this.filters.list_type;
    this.filters = d;
    this.getList();
  }
  private changeListType(type: any) {
    this.filters.list_type = type;
    localStorage.setItem(
      "biaozhuzhuangtaiFilter",
      JSON.stringify(this.filters)
    );
    this.$forceUpdate();
  }
  private goRead(item: any) {
    if (!this.getIsShowQuanxian("任务标注查看")) {
      return;
    }
    this.$store.commit("updateIfCompose", false);
    this.$router.push({
      path: "/main/mark/biaozhu/detail",
      query: {
        id: item.id,
        kind: "任务标注",
      },
    });
  }
  private goBiaozhu(item: any) {
    if (!this.getIsShowQuanxian("任务标注标注")) {
      return;
    }
    this.$store.commit("updateIfCompose", false);
    this.$router.push({
      path: "/main/mark/biaozhu/detail",
      query: {
        id: item.id,
        kind: "任务标注",
      },
    });
  }
  private openjindu(item: any) {
    if (!this.getIsShowQuanxian("任务标注标注进度")) {
      return;
    }
    this.jingduId = item.id;
    this.ifShowJindu = true;
  }
  /**
   * @description 获取用户列表
   */
  getList() {
    const params: any = {
      params: {
        search: this.filters.search,
        标注状态: this.filters["标注状态"],
        category: this.filters["分类"],
        order_field: "更新时间",
        order_value: this.filters.sort_value,
        current_page: this.filters.current_page,
        page_num: this.filters.page_count,
        kind: "任务标注",
      },
    };
    localStorage.setItem(
      "biaozhuzhuangtaiFilter",
      JSON.stringify(this.filters)
    );
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetRenwuList(this, params, loading)
      .then((data: any) => {
        loading.close();
        this.data = data.data;
        this.filters.total_count = data.total_count;
        this.filters.total_page = data.total_page;
        if (this.data.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  private getFenlei() {
    const params: any = {};
    GetCategory(this, params).then((res: any) => {
      this.fenleis = res;
    });
  }
  /**
   * @description 初始化
   */
  mounted() {
    this.filters.list_type = "text";
    this.filters["分类"] = "";
    this.filters["标注状态"] = "";
    this.filters.page_count = 10;
    const d = localStorage.getItem("biaozhuzhuangtaiFilter") || "";
    if (d) {
      this.filters = JSON.parse(d);
    }
    if (!this.filters.list_type) {
      this.filters.list_type = "text";
    }
    this.getFenlei();
    this.getList();
  }
}
